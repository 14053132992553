<template>
    <!-- 审批同意弹窗 -->
    <div class="approval-approved-dialog">
        <div class="advice">
            <el-input
                v-model="approveOpinionNew"
                type="textarea"
                rows="5"
                placeholder="请填写回复"
                maxlength="200"
                show-word-limit
                class="advice-input"
            />
            <div class="recommend-list">
                推荐回复
                <span
                    class="recommend-item"
                    v-for="(item, index) in recommendList"
                    :key="index"
                    @click="handleRecommend(item)"
                >
                    {{ item }}
                </span>
            </div>
        </div>
        <div class="signature">
            <div
                class="placeholder"
                v-if="!isSigned"
            >
                <img
                    src="@/assets/images/campusOA/signature.png"
                    alt=""
                />
                <p>去签名（必填）</p>
            </div>
            <!-- 手写签名 -->
            <SignatureCust
                v-if="!signatureUrl"
                ref="signCust"
                :width="624"
                :height="234"
                @drawStart="isSigned = true"
            />
            <!-- 签名的图片 -->
            <div
                class="signature-img"
                v-if="signatureUrl"
            >
                <img
                    :src="signatureUrl"
                    alt=""
                />
            </div>
            <!-- 操作按钮 -->
            <div class="btns">
                <el-button
                    type="text"
                    @click="openPhone"
                    v-if="!isSigned"
                    >手机扫二维码签名</el-button
                >
                <el-button
                    type="text"
                    v-if="!isSigned && haveHistorySign"
                    @click="useHistorySign"
                    >使用历史签名</el-button
                >
                <el-button
                    type="text"
                    v-if="isSigned"
                    @click="resetSign"
                    >重新签名</el-button
                >
            </div>
        </div>
        <div :style="{'text-align': 'right'}">
            <el-button
                type="enquiry"
                @click="handleCancel"
                >取消</el-button
            >
            <el-button
                type="primary"
                @click="handleConfirm"
                >确定</el-button
            >
        </div>

        <!-- 手机二维码弹窗 -->
        <dialog-wrapper 
            :dialogObj="phoneQrcodeObj" 
            @handleClose="closePhone"
        >
            <div class="pnoneCode">
                <div ref="canvasWrapper" ></div>
                <p class="tip">请使用手机扫描二维码，并在5分钟之内在手机端完成签名</p>
                <div class="opretae-btn">
                    <el-button plain @click="closePhone">取消</el-button>
                </div>
                
            </div>
        </dialog-wrapper>
    </div>
</template>

<script>
import SignatureCust from "./SignatureCust.vue"
import { DialogWrapper } from "common-local";
import { mapState } from "vuex";
import { CampusOAModel } from "@/models/CampusOA";
// qrcode插件生成二维码
import QRCode from 'qrcode'
import { getToken } from "@/libs/auth";

export default {
    name: "cancelLeaveDialog",
    components: {
        DialogWrapper,
        SignatureCust
    },
    data() {
        return {
            approveOpinionNew: "", // 审批意见
            recommendList: [
                "同意",
                "确认",
                "OK",
                "好的",
                "通过",
                "已审核",
                "已核实",
            ],
            isSigned: false, // 是否已签名
            haveHistorySign: false, // 是否有历史签名
            signatureUrl: "", // 签名图片地址
            phoneQrcodeObj: { // 手机二维码弹窗
                title: '电子签名',
                dialogVisible: false,
                width: '590px',
                customClass: 'phone-qr-dialog',
            },
        };
    },
    props: {
        data: {},
        admissionNeedApplyData: {},
        approveOpinion: {},
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            userId: (state) => state.userId,
        }),
    },
    watch: {},
    mounted() {
        this.approveOpinionNew = this.approveOpinion;
        this.useHistorySign();
    },
    methods: {
        /**
         * @Description: 点击推荐回复
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2024-10-31 15:51:59
         */        
        handleRecommend(item) {
            this.approveOpinionNew = this.approveOpinionNew + item;
        },
        /**
         * @Description: 手机端扫描二维码
         * @Author: hjm
         * @Date: 2024-10-31 09:23:40
         */        
        async openPhone(){
            // 先获取一下历史签名
            let oldSign = await this.getHistorySign();
            // 打开手机端二维码
            this.phoneQrcodeObj.dialogVisible = true;
            setTimeout(() => {
                this.generateCode(this.data)
            }, 300);
            // 开始轮询获取手机端签名
           
            let maxTime = 5*60*1000; // 5分钟
            let delay = 5*1000; // 5秒
            let count = 1;
            let maxCount = Math.ceil(maxTime/delay);
            this.timer = setInterval(async () => {
                count++;
                let newSign = await this.getHistorySign();
                console.log(count, maxCount, newSign, oldSign);
                if(count >= maxCount) {
                    // 超过5分钟，停止轮询
                    this.$message.warning("手机端签名超时，请重新签名");
                    this.closePhone();
                }
                if(newSign && newSign != oldSign) {
                    // 如果新的历史签名和旧的历史签名不一样了，说明手机端已经签名，停止轮询
                    this.isSigned = true;
                    this.signatureUrl = newSign;
                    this.closePhone();
                }
            }, delay)
        },
        /**
         * @Description:  生成二维码
         * @Author: 韩俊梅
         * @Date: 2024-10-17 09:50:42
         */    
        generateCode(data) {
            this.$refs.canvasWrapper.innerHTML = '';
            QRCode.toCanvas(`https://cloudcampus.xinkaoyun.com:40005/campus-oa/sign?token=${getToken()}&userId=${this.userId}`, {
                width: 240, // 二维码宽度
            }, (error, canvas) => {
                if (error) {
                    console.error(error)
                    return
                }
                // 在canvas的父元素中插入canvas元素
                this.$refs.canvasWrapper.appendChild(canvas)
            })
        }, 
        /**
         * @Description: 关闭二维码弹窗
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2024-11-13 11:55:07
         */        
        closePhone() {
            if(this.timer) clearInterval(this.timer);
            this.phoneQrcodeObj.dialogVisible = false;
        },
        /**
         * @Description: 获取历史签名
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2024-10-31 16:12:22
         */        
        getHistorySign() {
            return new Promise((resolve, reject) => {
                const campusOA = new CampusOAModel();
                campusOA.getHistorySign({
                    userId: this.userId,
                }).then((res) => {
                    if(res.data) {
                        resolve(res.data.data)
                    } else {
                        resolve('');
                    }
                }).catch((err) => {
                    reject(err);
                })
            })
        },
        /**
         * @Description: 使用历史签名
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2024-11-04 16:39:51
         */        
        async useHistorySign() {
            
            let historySign = await this.getHistorySign();
            if (historySign) {
                this.isSigned = true;
                this.signatureUrl = historySign;
                this.haveHistorySign = true;
            } else {
                this.isSigned = false;
                this.signatureUrl = "";
                this.haveHistorySign = false;
            }
        },
        base64ToFile(base64Data, filename) {
            // 将base64的数据部分提取出来
            const parts = base64Data.split(';base64,');
            const contentType = parts[0].split(':')[1];
            const raw = window.atob(parts[1]);
            
            // 将原始数据转换为Uint8Array
            const rawLength = raw.length;
            const uInt8Array = new Uint8Array(rawLength);
            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }
            
            // 使用Blob对象创建文件
            const blob = new Blob([uInt8Array], {type: contentType});
            return new File([blob], filename, {type: contentType});
        },
        /**
         * @Description: 重新签名
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2024-10-31 16:13:09
         */ 
        resetSign(){
            this.signatureUrl = "";
            this.isSigned = false;
            this.$nextTick(() => {
                this.$refs.signCust.reset();
            })
        },
        /**
         * @Description: 取消
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2024-10-31 16:13:09
         */ 
        handleCancel() {
            this.approveOpinionNew = "";
            this.signatureUrl = "";
            this.isSigned = false;
            this.$emit("handleClose");
        },
        /**
         * @Description: 确定
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2024-10-31 16:13:09
         */ 
        handleConfirm() {
            if(!this.signatureUrl) {
                if(!this.isSigned) {
                    return this.$message.warning("请先签名");
                } else {
                    // 说明手写了，但是还没有保存签名，没有获取到签名的图片地址
                    this.$refs.signCust.generate().then((base64) => {
                        console.log(base64)
                        const file = this.base64ToFile(base64, "sign.png")
                        console.log(file)
                        let formData = new FormData();
                        formData.append('file', file);
                        console.log(this.userId,'userId');
                        formData.append('userId', this.userId);
                        formData.append('rotation', '');
                        console.log(formData,'formData');
                        const campusOA = new CampusOAModel();
                        campusOA.saveSign(formData)
                        .then((res) => {
                            console.log(res)
                            if(res.data.data) {
                                this.isSigned = true;
                                this.signatureUrl = res.data.data;
                                this.$emit("handleConfirm", this.data, this.admissionNeedApplyData, this.approveOpinionNew);
                            } else {
                                this.$message.error('签名失败，请重试');
                            }
                        })
                        .catch(err=>{
                            console.log(err)
                            this.$message.error('签名失败，请重试');
                        })
                    })
                } 
            } else {
                this.$emit("handleConfirm", this.data, this.admissionNeedApplyData, this.approveOpinionNew);
            }
            
        }
    },
};
</script>

<style scoped lang="scss">
.approval-approved-dialog {
    .advice {
        ::v-deep .el-textarea {
            .el-textarea__inner {
                font-family: Microsoft YaHei;
            }
        }

        .recommend-list {
            font-size: 14px;
            color: #2b2f33;

            .recommend-item {
                display: inline-block;
                margin-left: 8px;
                margin-top: 10px;
                background: #f3f3f3;
                border-radius: 12px;
                font-size: 12px;
                color: #2b2f33;
                padding: 3px 16px;
                border-radius: 12px;
                cursor: pointer;

                &:first-child {
                    margin-left: 32px;
                }

                &:hover {
                    background: #d9e6ff;
                }
            }
        }
    }

    .signature {
        margin: 16px 0 24px 0;
        border-radius: 4px;
        border: 1px solid #dcdee0;
        padding-bottom: 32px;
        position: relative;

        .placeholder {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 14px;
            color: #999999;
            text-align: center;
        }

        .signature-img {
            img {
                width: 100%;
            }
        }

        .btns {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 32px;
            text-align: right;

            .el-button {
                color: #3c7fff;
            }
        }
    }
}

/deep/ .el-dialog.phone-qr-dialog {
    .el-dialog__body {
        text-align: center;

        .pnoneCode {
            .tip {
                font-size: 15px;
                color: #2B2F33;
                margin-top: 32px;
                margin-bottom: 60px;
            }

            .opretae-btn {
                text-align: right;
            }
        }

    }
}
</style>
